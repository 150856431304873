.delete-file-icon {
    position: absolute;
    top: 30;
    right: 30;
    background: red;
    border-radius: 50%;
    width: 22px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transform: translate(50%, -50%);
}

.delete-icon {
    color: white;
    font-weight: bold;
    font-size: 17px;
}

.doc-wrapper{ 
    display: inline-flex; 
    margin-right: 7px; 
    cursor: pointer;
}