.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    position: relative;
}

.dropzone {
    border: 2px dashed #777777;
    border-radius: 4px;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    margin: 10px 0;
    background-color: #6fc7b5;
    color: #173358;
    font-weight: bold;
    min-height: 170px;
    display: flex;
    justify-content: center;
    align-items: center; /* Added for vertical centering */
}

.dropzone.dragover {
    border-color: #6fc7b5;
    color: #6fc7b5;
    background-color: #173358;
}

.drag-text {
    margin: 0;
}

.skip-upload {
    padding: 10px 20px;
    border-radius: 5px;
    background-color: #0A203A;
    font-weight: bold;
    color: #6FC7B6;
    cursor: pointer;
    margin: auto;
    display: block; /* This allows margin auto to work for centering */
}

.skip-upload:hover {
    background-color: #6FC7B6;
    color: #0A203A;
}

.success-message {
    color: #6FC7B6;
    background: rgba(0, 0, 0, 0.7);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #e5ffeb; /* Light green background for visibility */
    padding: 10px 20px;
    border-radius: 5px;
    color: green;
    font-weight: bold;
    text-align: center;
    z-index: 9999;
}

.filenames{
    font-size: xx-small;
}